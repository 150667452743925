const SuspenseFallback = (
  <div
    style={{
      height: "100vh",
      display: "grid",
      placeItems: "center",
    }}
  >
    <div className="spinner-border text-info" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

export default SuspenseFallback;
