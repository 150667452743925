import axios from "axios";
import Cookies from "js-cookie";

export const getCountry = async () => {
  try {
    let country = Cookies.get("country");
    if (!country) {
      const res = await axios.get("https://ipapi.co/country/");
      Cookies.set("country", res.data, { expires: 7 });
      return { success: true, data: res.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return error;
  }
};
